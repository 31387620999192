import React from 'react'
import { EmptyState, InfoIcon, Button, Flex } from '@buffer-mono/popcorn'

import styles from './InstagramFacebookReAuthMessage.module.css'

export function InstagramFacebookReAuthMessage({
  name,
  channelId,
}: {
  name: string
  channelId: string
}): JSX.Element {
  return (
    <div className={styles.base}>
      <EmptyState size="medium" variant="primary">
        <EmptyState.Icon>
          <InfoIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>
          Unlock Instagram Analytics for '{name}'
        </EmptyState.Heading>
        <EmptyState.Description>
          To access Instagram analytics, link your Instagram to a Facebook Page.
        </EmptyState.Description>
        <EmptyState.Actions>
          <Flex direction="column" gap="sm" align="center">
            <Button
              onClick={(): void => {
                const { MODALS, actions } = window?.appshell || {}
                actions.openModal(MODALS.instagramFacebookReAuth, {
                  channelId,
                })
              }}
            >
              Show Me How
            </Button>

            <Button
              variant="tertiary"
              onClick={(): void => {
                window.location.assign(
                  'https://support.buffer.com/article/568-connecting-your-instagram-business-or-creator-account-to-buffer#Converting-and-linking-your-Instagram-account-to-a-Facebook-Page--TLG6Z?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more',
                )
              }}
            >
              Learn More
            </Button>
          </Flex>
        </EmptyState.Actions>
      </EmptyState>
    </div>
  )
}
